:root {
	--background-color: rgba(114, 119, 143, 1.0);

	--peer-shadow: 0px /* 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12) */;
	--peer-border: 0px /* solid rgba(49, 49, 49, 0.9) */;
	--peer-empty-avatar: url('./images/buddy.svg');
	--peer-bg-color: rgba(49, 49, 49, 0.9);
	--peer-video-bg-color: rgba(19, 19, 19, 1);

	--active-speaker-border-color: rgba(255, 255, 255, 1.0);
	--selected-peer-border-color: rgba(55, 126, 255, 1.0);
	--active-speaker-shadow: 0px 0px 8px rgba(255, 255, 255, 0.9);
}

html
{
	height: 100%;
	width: 100%;
	font-family: 'Roboto';
	font-weight: 300;
	margin : 0;
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body
{
	height: 100%;
	width: 100%;
	font-size: 16px;
	margin: 0;
}

#swemelmeet
{
	height: 100%;
	width: 100%;
}